import { API_URL } from "../../constants/ApiConstant";

const getRequest = async (url, token = null) => {
  const config = {
    method: "GET",
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (token) config.headers.Authorization = token;
  return await request(url, config);
};
const postRequest = async (url, body = {}, token = null) => {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (token) config.headers.Authorization = token;

  return await request(url, config);
};
const postFileRequest = async (url, formData = {}, token = null) => {
  const config = {
    method: "POST",
    body: formData,
  };

  if (token) config.headers.Authorization = token;

  return await requestFile(url, config);
};

const requestFile = async (url, config) => {
  let status = -1;
  let error = null;
  let result = null;

  try {
    const response = await fetch(`${API_URL}${url}`, config);
    status = response.status;
    result = await response.blob();
    const imageObjectURL = URL.createObjectURL(result);
    result = imageObjectURL || null;
  } catch (e) {
    error = e.message;
  } finally {
    return handleResponse(result, status, error);
  }
};

const putRequest = async (url, body = {}, token = null) => {
  const config = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };
  console.log(body);
  if (token) config.headers.Authorization = token;

  return await request(url, config);
};

const deleteRequest = async (url, body = {}, token = null) => {
  const config = {
    method: "DELETE",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (token) config.headers.Authorization = token;

  return await request(url, config);
};
const request = async (url, config) => {
  let status = -1;
  let error = null;
  let result = null;

  try {
    const response = await fetch(`${API_URL}${url}`, config);
    status = response.status;
    result = await response.json();
  } catch (e) {
    error = e.message;
  } finally {
    return handleResponse(status, result, error);
  }
};

const handleResponse = (status, result, error) => {
  const hasError = !result || status >= 400;
  return {
    status,
    result: hasError ? null : result,
    error: hasError ? `Result is null ${error || ""}` : null,
  };
};
export {
  getRequest,
  postRequest,
  request,
  handleResponse,
  putRequest,
  deleteRequest,
  postFileRequest,
};

// import React, { useState } from "react";
// import "./vehicleCreate.module.scss";
// import { useDispatch } from "react-redux";
// import { createVehicle } from "../../../redux/vehicleRedux/requestVehicle";
// import { createMedia } from "../../../redux/mediaRedux/requestMedia";

// const VehicleCreate = () => {
//   const dispatch = useDispatch();
//   const [formCount, setFormCount] = useState(1);
//   const [showForm, setShowForm] = useState(true);

//   const [typeOfVehicle, setTypeOfVehicle] = useState("");
//   const [brand, setBrand] = useState("");
//   const [vehicleNumber, setVehicleNumber] = useState("");
//   const [range, setRange] = useState("");
//   const [power, setPower] = useState("");
//   const [charging, setCharging] = useState("");
//   const [maximunSpeed, setMaximunSpeed] = useState("");
//   const [typeOfPlug, setTypeOfPlug] = useState("");
//   const [passenger, setPassenger] = useState("");
//   const [cargo, setCargo] = useState("");
//   const [door, setDoor] = useState("");
//   const [categorie, setCategorie] = useState("");
//   const [bikeSize, setBikeSize] = useState("");
//   const [bikeWeight, setBikeWeight] = useState("");
//   const [priceByDay, setPriceByDay] = useState("");
//   const [mediaFiles, setMediaFiles] = useState("");
//   const [mediaDescriptions, setMediaDescriptions] = useState("");

//   const newVehicle = {
//     typeOfVehicle,
//     brand,
//     vehicleNumber,
//     range,
//     power,
//     charging,
//     maximunSpeed,
//     typeOfPlug,
//     passenger,
//     cargo,
//     door,
//     categorie,
//     bikeSize,
//     bikeWeight,
//     priceByDay,
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const vehicleId = await dispatch(createVehicle(newVehicle));
//     const id = vehicleId.payload.vehicleId;
//     if (vehicleId) {
//       const mediaData = mediaFiles.map((file, index) => ({
//         image: file,
//         description: mediaDescriptions[index],
//         vehicleId: id,
//       }));
//       await dispatch(createMedia(mediaData));
//     }
//   };

//   const handleAddFormFirst = (e) => {
//     e.preventDefault();
//     setFormCount(formCount + 1);
//     setShowForm(true);
//   };

//   const handleAddForm = (e) => {
//     e.preventDefault();
//     setFormCount(formCount - 1);
//   };

//   const handleMediaFileChange = (index, files) => {
//     const updatedFiles = [...mediaFiles];
//     updatedFiles[index] = Array.from(files);
//     setMediaFiles(updatedFiles);
//   };

//   const handleMediaDescriptionChange = (index, value) => {
//     const updatedDescriptions = [...mediaDescriptions];
//     updatedDescriptions[index] = value;
//     setMediaDescriptions(updatedDescriptions);
//   };

//   return (
//     <section className="containerVehicleCreate">
//       <h2>Enregistrer un véhicule</h2>
//       <form onSubmit={handleSubmit} className="formVehicleCreate">
//         <div>
//           <label htmlFor="typeOfVehicle">Type de véhicule</label>
//           <select
//             name="typeOfVehicle"
//             id="typeOfVehicle"
//             value={typeOfVehicle}
//             required
//             onChange={(e) => setTypeOfVehicle(e.target.value)}
//           >
//             <option value="#">choisir le type</option>
//             <option value="car">voiture</option>
//             <option value="scooter">scooter</option>
//             <option value="elecScooter">trottinette</option>
//             <option value="bike">vélo</option>
//           </select>
//         </div>
//         <div>
//           <label htmlFor="brand">Marque</label>
//           <input
//             type="text"
//             id="brand"
//             name="brand"
//             required
//             value={brand}
//             onChange={(e) => setBrand(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="vehicleNumber">Numéro de véhicule</label>
//           <input
//             type="text"
//             id="vehicleNumber"
//             name="vehicleNumber"
//             required
//             value={vehicleNumber}
//             onChange={(e) => setVehicleNumber(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="range">Autonomie</label>
//           <input
//             type="text"
//             id="range"
//             name="range"
//             required
//             value={range}
//             onChange={(e) => setRange(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="power">Puissance</label>
//           <input
//             type="text"
//             id="power"
//             name="power"
//             value={power}
//             onChange={(e) => setPower(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="charging">Recharge</label>
//           <input
//             type="text"
//             id="charging"
//             name="charging"
//             required
//             value={charging}
//             onChange={(e) => setCharging(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="maximunSpeed">Vitesse maximale</label>
//           <input
//             type="text"
//             id="maximunSpeed"
//             name="maximunSpeed"
//             value={maximunSpeed}
//             onChange={(e) => setMaximunSpeed(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="typeOfPlug">Type de prise</label>
//           <input
//             type="text"
//             id="typeOfPlug"
//             name="typeOfPlug"
//             required
//             value={typeOfPlug}
//             onChange={(e) => setTypeOfPlug(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="passenger">Nombre de passagers</label>
//           <input
//             type="text"
//             id="passenger"
//             name="passenger"
//             value={passenger}
//             onChange={(e) => setPassenger(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="cargo">Capacité de chargement</label>
//           <input
//             type="text"
//             id="cargo"
//             name="cargo"
//             required
//             value={cargo}
//             onChange={(e) => setCargo(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="door">Nombre de portes</label>
//           <input
//             type="text"
//             id="door"
//             name="door"
//             value={door}
//             onChange={(e) => setDoor(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="categorie">Catégorie</label>
//           <input
//             type="text"
//             id="categorie"
//             name="categorie"
//             value={categorie}
//             onChange={(e) => setCategorie(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeSize">Taille du vélo</label>{" "}
//           <input
//             type="text"
//             id="bikeSize"
//             name="bikeSize"
//             value={bikeSize}
//             onChange={(e) => setBikeSize(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeWeight">Poids vélo</label>
//           <input
//             type="text"
//             id="bikeWeight"
//             name="bikeWeight"
//             value={bikeWeight}
//             onChange={(e) => setBikeWeight(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="priceByDay">Prix par jour</label>
//           <input
//             type="text"
//             id="priceByDay"
//             name="priceByDay"
//             required
//             value={priceByDay}
//             onChange={(e) => setPriceByDay(e.target.value)}
//           />
//         </div>
//         <span className="titleMedia">Média</span>
//         {Array.from({ length: formCount }).map(
//           (_, index) =>
//             showForm && (
//               <div key={index}>
//                 <>
//                   <div>
//                     <label htmlFor={`fileImage${index}`}>Nom image</label>
//                     <input
//                       type="file"
//                       id={`fileImage${index}`}
//                       name={`fileImage${index}`}
//                       value={mediaFiles}
//                       multiple
//                       // value={mediaFiles}
//                       // multiple
//                       onChange={(e) =>
//                         handleMediaFileChange(index, e.target.files)
//                       }
//                     />
//                   </div>
//                   <div>
//                     <label htmlFor={`description${index}`}>Description</label>
//                     <input
//                       type="text"
//                       id={`description${index}`}
//                       name={`description${index}`}
//                       // value={mediaDescriptions[index] || ""}
//                       value={mediaDescriptions[index] || ""}
//                       onChange={(e) =>
//                         handleMediaDescriptionChange(index, e.target.value)
//                       }
//                     />
//                   </div>
//                 </>
//                 <button className="btn" onClick={handleAddForm}>
//                   Annuler media
//                 </button>
//               </div>
//             )
//         )}

//         <button className="btn" onClick={handleAddFormFirst}>
//           Ajouter media
//         </button>

//         <input type="submit" value="Valider" />
//       </form>
//     </section>
//   );
// };

// export default VehicleCreate;

// import React, { useState } from "react";
// import "./vehicleCreate.module.scss";
// import { useDispatch } from "react-redux";
// import { createVehicle } from "../../../redux/vehicleRedux/requestVehicle";
// import { createMedia } from "../../../redux/mediaRedux/requestMedia";

// const VehicleCreate = () => {
//   const dispatch = useDispatch();
//   const [formCount, setFormCount] = useState(1);
//   const [showForm, setShowForm] = useState(true);

//   const [typeOfVehicle, setTypeOfVehicle] = useState("");
//   const [brand, setBrand] = useState("");
//   const [vehicleNumber, setVehicleNumber] = useState("");
//   const [range, setRange] = useState("");
//   const [power, setPower] = useState("");
//   const [charging, setCharging] = useState("");
//   const [maximunSpeed, setMaximunSpeed] = useState("");
//   const [typeOfPlug, setTypeOfPlug] = useState("");
//   const [passenger, setPassenger] = useState("");
//   const [cargo, setCargo] = useState("");
//   const [door, setDoor] = useState("");
//   const [categorie, setCategorie] = useState("");
//   const [bikeSize, setBikeSize] = useState("");
//   const [bikeWeight, setBikeWeight] = useState("");
//   const [priceByDay, setPriceByDay] = useState("");
//   const [mediaFiles, setMediaFiles] = useState("");
//   const [mediaDescriptions, setMediaDescriptions] = useState("");

//   const newVehicle = {
//     typeOfVehicle,
//     brand,
//     vehicleNumber,
//     range,
//     power,
//     charging,
//     maximunSpeed,
//     typeOfPlug,
//     passenger,
//     cargo,
//     door,
//     categorie,
//     bikeSize,
//     bikeWeight,
//     priceByDay,
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const vehicleId = await dispatch(createVehicle(newVehicle));
//     const id = vehicleId.payload.vehicleId;
//     if (vehicleId) {
//       const mediaData = mediaFiles.map((file, index) => ({
//         image: file,
//         description: mediaDescriptions[index],
//         vehicleId: id,
//       }));
//       await dispatch(createMedia(mediaData));
//     }
//   };

//   const handleAddFormFirst = (e) => {
//     e.preventDefault();
//     setFormCount(formCount + 1);
//     setShowForm(true);
//   };

//   const handleAddForm = (e) => {
//     e.preventDefault();
//     setFormCount(formCount - 1);
//   };

//   const handleMediaFileChange = (index, files) => {
//     const updatedFiles = [...mediaFiles];
//     updatedFiles[index] = Array.from(files);
//     setMediaFiles(updatedFiles);
//   };

//   const handleMediaDescriptionChange = (index, value) => {
//     const updatedDescriptions = [...mediaDescriptions];
//     updatedDescriptions[index] = value;
//     setMediaDescriptions(updatedDescriptions);
//   };

//   return (
//     <section className="containerVehicleCreate">
//       <h2>Enregistrer un véhicule</h2>
//       <form onSubmit={handleSubmit} className="formVehicleCreate">
//         <div>
//           <label htmlFor="typeOfVehicle">Type de véhicule</label>
//           <select
//             name="typeOfVehicle"
//             id="typeOfVehicle"
//             value={typeOfVehicle}
//             required
//             onChange={(e) => setTypeOfVehicle(e.target.value)}
//           >
//             <option value="#">choisir le type</option>
//             <option value="car">voiture</option>
//             <option value="scooter">scooter</option>
//             <option value="elecScooter">trottinette</option>
//             <option value="bike">vélo</option>
//           </select>
//         </div>
//         <div>
//           <label htmlFor="brand">Marque</label>
//           <input
//             type="text"
//             id="brand"
//             name="brand"
//             required
//             value={brand}
//             onChange={(e) => setBrand(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="vehicleNumber">Numéro de véhicule</label>
//           <input
//             type="text"
//             id="vehicleNumber"
//             name="vehicleNumber"
//             required
//             value={vehicleNumber}
//             onChange={(e) => setVehicleNumber(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="range">Autonomie</label>
//           <input
//             type="text"
//             id="range"
//             name="range"
//             required
//             value={range}
//             onChange={(e) => setRange(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="power">Puissance</label>
//           <input
//             type="text"
//             id="power"
//             name="power"
//             value={power}
//             onChange={(e) => setPower(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="charging">Recharge</label>
//           <input
//             type="text"
//             id="charging"
//             name="charging"
//             required
//             value={charging}
//             onChange={(e) => setCharging(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="maximunSpeed">Vitesse maximale</label>
//           <input
//             type="text"
//             id="maximunSpeed"
//             name="maximunSpeed"
//             value={maximunSpeed}
//             onChange={(e) => setMaximunSpeed(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="typeOfPlug">Type de prise</label>
//           <input
//             type="text"
//             id="typeOfPlug"
//             name="typeOfPlug"
//             required
//             value={typeOfPlug}
//             onChange={(e) => setTypeOfPlug(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="passenger">Nombre de passagers</label>
//           <input
//             type="text"
//             id="passenger"
//             name="passenger"
//             value={passenger}
//             onChange={(e) => setPassenger(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="cargo">Capacité de chargement</label>
//           <input
//             type="text"
//             id="cargo"
//             name="cargo"
//             required
//             value={cargo}
//             onChange={(e) => setCargo(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="door">Nombre de portes</label>
//           <input
//             type="text"
//             id="door"
//             name="door"
//             value={door}
//             onChange={(e) => setDoor(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="categorie">Catégorie</label>
//           <input
//             type="text"
//             id="categorie"
//             name="categorie"
//             value={categorie}
//             onChange={(e) => setCategorie(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeSize">Taille du vélo</label>{" "}
//           <input
//             type="text"
//             id="bikeSize"
//             name="bikeSize"
//             value={bikeSize}
//             onChange={(e) => setBikeSize(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeWeight">Poids vélo</label>
//           <input
//             type="text"
//             id="bikeWeight"
//             name="bikeWeight"
//             value={bikeWeight}
//             onChange={(e) => setBikeWeight(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="priceByDay">Prix par jour</label>
//           <input
//             type="text"
//             id="priceByDay"
//             name="priceByDay"
//             required
//             value={priceByDay}
//             onChange={(e) => setPriceByDay(e.target.value)}
//           />
//         </div>
//         <span className="titleMedia">Média</span>
//         {Array.from({ length: formCount }).map((_, index) => (
//           <div key={index}>
//             <div>
//               <label htmlFor={`fileImage-${index}`}>Nom image</label>
//               <input
//                 type="file"
//                 id={`fileImage-${index}`}
//                 name={`fileImage-${index}`} // Make sure the name attribute matches the expected API field name
//                 value={mediaFiles}
//                 multiple
//                 onChange={(e) => handleMediaFileChange(index, e.target.files)}
//               />
//             </div>
//             <div>
//               <label htmlFor={`description-${index}`}>Description</label>
//               <input
//                 type="text"
//                 id={`description-${index}`}
//                 name={`description-${index}`} // Make sure the name attribute matches the expected API field name
//                 value={mediaDescriptions[index] || ""}
//                 onChange={(e) =>
//                   handleMediaDescriptionChange(index, e.target.value)
//                 }
//               />
//             </div>
//             <button className="btn" onClick={() => handleAddForm(index)}>
//               Annuler media
//             </button>
//           </div>
//         ))}

//         <button className="btn" onClick={handleAddFormFirst}>
//           Ajouter media
//         </button>

//         <input type="submit" value="Valider" />
//       </form>
//     </section>
//   );
// };

// export default VehicleCreate;
