import React, { useState } from "react";
import "./vehicleCreate.module.scss";
import { useDispatch } from "react-redux";
import { createVehicle } from "../../../redux/vehicleRedux/requestVehicle";
import { createMedia } from "../../../redux/mediaRedux/requestMedia";

const VehicleCreate = () => {
  const dispatch = useDispatch();

  const [typeOfVehicle, setTypeOfVehicle] = useState("");
  const [brand, setBrand] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [range, setRange] = useState("");
  const [power, setPower] = useState("");
  const [charging, setCharging] = useState("");
  const [maximunSpeed, setMaximunSpeed] = useState("");
  const [typeOfPlug, setTypeOfPlug] = useState("");
  const [passenger, setPassenger] = useState("");
  const [cargo, setCargo] = useState("");
  const [door, setDoor] = useState("");
  const [categorie, setCategorie] = useState("");
  const [bikeSize, setBikeSize] = useState("");
  const [bikeWeight, setBikeWeight] = useState("");
  const [priceByDay, setPriceByDay] = useState("");
  const [mediaName, setMediaName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");

  const newVehicle = {
    typeOfVehicle,
    brand,
    vehicleNumber,
    range,
    power,
    charging,
    maximunSpeed,
    typeOfPlug,
    passenger,
    cargo,
    door,
    categorie,
    bikeSize,
    bikeWeight,
    priceByDay,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const vehicle = await dispatch(createVehicle(newVehicle));
    // const id = localStorage.getItem("vehicleId");
    const id = vehicle.payload.vehicleId;
    if (vehicle) {
      const newMedia = {
        image: mediaName,
        description: mediaDescription,
        vehicleId: id,
      };
      await dispatch(createMedia(newMedia));
    }

    setTypeOfVehicle("");
    setBrand("");
    setVehicleNumber("");
    setRange("");
    setPower("");
    setCharging("");
    setMaximunSpeed("");
    setTypeOfPlug("");
    setPassenger("");
    setCargo("");
    setDoor("");
    setCategorie("");
    setBikeSize("");
    setBikeSize("");
    setBikeWeight("");
    setPriceByDay("");
    setMediaName("");
    setMediaDescription("");
  };

  return (
    <section className="containerVehicleCreate">
      <h2>Enregistrer un véhicule</h2>
      <form onSubmit={handleSubmit} className="formVehicleCreate">
        <div>
          <label htmlFor="typeOfVehicle">Type de véhicule</label>
          <select
            name="typeOfVehicle"
            id="typeOfVehicle"
            value={typeOfVehicle}
            required
            onChange={(e) => setTypeOfVehicle(e.target.value)}
          >
            <option value="#">choisir le type</option>
            <option value="car">voiture</option>
            <option value="scooter">scooter</option>
            <option value="elecScooter">trottinette</option>
            <option value="bike">vélo</option>
          </select>
        </div>
        <div>
          <label htmlFor="brand">Marque</label>
          <input
            type="text"
            id="brand"
            name="brand"
            required
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="vehicleNumber">Numéro de véhicule</label>
          <input
            type="text"
            id="vehicleNumber"
            name="vehicleNumber"
            required
            value={vehicleNumber}
            onChange={(e) => setVehicleNumber(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="range">Autonomie</label>
          <input
            type="text"
            id="range"
            name="range"
            required
            value={range}
            onChange={(e) => setRange(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="power">Puissance</label>
          <input
            type="text"
            id="power"
            name="power"
            value={power}
            onChange={(e) => setPower(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="charging">Recharge</label>
          <input
            type="text"
            id="charging"
            name="charging"
            required
            value={charging}
            onChange={(e) => setCharging(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="maximunSpeed">Vitesse maximale</label>
          <input
            type="text"
            id="maximunSpeed"
            name="maximunSpeed"
            value={maximunSpeed}
            onChange={(e) => setMaximunSpeed(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="typeOfPlug">Type de prise</label>
          <input
            type="text"
            id="typeOfplug"
            name="typeOfPlug"
            required
            value={typeOfPlug}
            onChange={(e) => setTypeOfPlug(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="passenger">Nombre de passagers</label>
          <input
            type="text"
            id="passenger"
            name="passenger"
            value={passenger}
            onChange={(e) => setPassenger(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="cargo">Capacité de chargement</label>
          <input
            type="text"
            id="cargo"
            name="cargo"
            required
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="door">Nombre de portes</label>
          <input
            type="text"
            id="door"
            name="door"
            value={door}
            onChange={(e) => setDoor(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="categorie">Catégorie</label>
          <input
            type="text"
            id="categorie"
            name="categorie"
            value={categorie}
            onChange={(e) => setCategorie(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="bikeSize">Taille du vélo</label>
          <input
            type="text"
            id="bikeSize"
            name="bikeSize"
            value={bikeSize}
            onChange={(e) => setBikeSize(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="bikeWeight">Poids vélo</label>
          <input
            type="text"
            id="bikeWeight"
            name="bikeWeight"
            value={bikeWeight}
            onChange={(e) => setBikeWeight(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="priceByDay">Prix par jour</label>
          <input
            type="text"
            id="priceByDay"
            name="priceByDay"
            required
            value={priceByDay}
            onChange={(e) => setPriceByDay(e.target.value)}
          />
        </div>
        <span className="titleMedia">Média</span>
        <div>
          <label htmlFor="image">Nom image</label>
          <input
            type="text"
            id="image"
            name="image"
            value={mediaName}
            onChange={(e) => setMediaName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            name="description"
            value={mediaDescription}
            onChange={(e) => setMediaDescription(e.target.value)}
          />
        </div>
        <input type="submit" value="Valider" />
      </form>
    </section>
  );
};

export default VehicleCreate;
// import React, { useState } from "react";
// import "./vehicleCreate.module.scss";
// import { useDispatch } from "react-redux";
// import { createVehicle } from "../../../redux/vehicleRedux/requestVehicle";
// import { createMedia } from "../../../redux/mediaRedux/requestMedia";

// const VehicleCreate = () => {
//   const dispatch = useDispatch();
//   const [formCount, setFormCount] = useState(1);
//   const [showForm, setShowForm] = useState(true);

//   const [typeOfVehicle, setTypeOfVehicle] = useState("");
//   const [brand, setBrand] = useState("");
//   const [vehicleNumber, setVehicleNumber] = useState("");
//   const [range, setRange] = useState("");
//   const [power, setPower] = useState("");
//   const [charging, setCharging] = useState("");
//   const [maximunSpeed, setMaximunSpeed] = useState("");
//   const [typeOfPlug, setTypeOfPlug] = useState("");
//   const [passenger, setPassenger] = useState("");
//   const [cargo, setCargo] = useState("");
//   const [door, setDoor] = useState("");
//   const [categorie, setCategorie] = useState("");
//   const [bikeSize, setBikeSize] = useState("");
//   const [bikeWeight, setBikeWeight] = useState("");
//   const [priceByDay, setPriceByDay] = useState("");
//   const [mediaFiles, setMediaFiles] = useState("");
//   const [mediaDescriptions, setMediaDescriptions] = useState("");
//   const [imagePreviews, setImagePreviews] = useState([]);

//   const newVehicle = {
//     typeOfVehicle,
//     brand,
//     vehicleNumber,
//     range,
//     power,
//     charging,
//     maximunSpeed,
//     typeOfPlug,
//     passenger,
//     cargo,
//     door,
//     categorie,
//     bikeSize,
//     bikeWeight,
//     priceByDay,
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const vehicleId = await dispatch(createVehicle(newVehicle));
//     const id = vehicleId.payload.vehicleId;
//     if (vehicleId) {
//       const mediaData = mediaFiles.map((file, index) => ({
//         image: file,
//         description: mediaDescriptions[index],
//         vehicleId: id,
//       }));
//       await dispatch(createMedia(mediaData));
//     }
//   };

//   const handleAddFormFirst = (e) => {
//     e.preventDefault();
//     setFormCount(formCount + 1);
//     setShowForm(true);
//   };

//   const handleAddForm = (e) => {
//     e.preventDefault();
//     setFormCount(formCount - 1);
//   };

//   const handleMediaFileChange = (index, files) => {
//     const updatedFiles = [...mediaFiles];
//     updatedFiles[index] = Array.from(files);
//     setMediaFiles(updatedFiles);
//   };

//   const handleMediaDescriptionChange = (index, value) => {
//     const updatedDescriptions = [...mediaDescriptions];
//     updatedDescriptions[index] = value;
//     setMediaDescriptions(updatedDescriptions);
//   };

//   return (
//     <section className="containerVehicleCreate">
//       <h2>Enregistrer un véhicule</h2>
//       <form onSubmit={handleSubmit} className="formVehicleCreate">
//         <div>
//           <label htmlFor="typeOfVehicle">Type de véhicule</label>
//           <select
//             name="typeOfVehicle"
//             id="typeOfVehicle"
//             value={typeOfVehicle}
//             required
//             onChange={(e) => setTypeOfVehicle(e.target.value)}
//           >
//             <option value="#">choisir le type</option>
//             <option value="car">voiture</option>
//             <option value="scooter">scooter</option>
//             <option value="elecScooter">trottinette</option>
//             <option value="bike">vélo</option>
//           </select>
//         </div>
//         <div>
//           <label htmlFor="brand">Marque</label>
//           <input
//             type="text"
//             id="brand"
//             name="brand"
//             required
//             value={brand}
//             onChange={(e) => setBrand(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="vehicleNumber">Numéro de véhicule</label>
//           <input
//             type="text"
//             id="vehicleNumber"
//             name="vehicleNumber"
//             required
//             value={vehicleNumber}
//             onChange={(e) => setVehicleNumber(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="range">Autonomie</label>
//           <input
//             type="text"
//             id="range"
//             name="range"
//             required
//             value={range}
//             onChange={(e) => setRange(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="power">Puissance</label>
//           <input
//             type="text"
//             id="power"
//             name="power"
//             value={power}
//             onChange={(e) => setPower(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="charging">Recharge</label>
//           <input
//             type="text"
//             id="charging"
//             name="charging"
//             required
//             value={charging}
//             onChange={(e) => setCharging(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="maximunSpeed">Vitesse maximale</label>
//           <input
//             type="text"
//             id="maximunSpeed"
//             name="maximunSpeed"
//             value={maximunSpeed}
//             onChange={(e) => setMaximunSpeed(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="typeOfPlug">Type de prise</label>
//           <input
//             type="text"
//             id="typeOfPlug"
//             name="typeOfPlug"
//             required
//             value={typeOfPlug}
//             onChange={(e) => setTypeOfPlug(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="passenger">Nombre de passagers</label>
//           <input
//             type="text"
//             id="passenger"
//             name="passenger"
//             value={passenger}
//             onChange={(e) => setPassenger(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="cargo">Capacité de chargement</label>
//           <input
//             type="text"
//             id="cargo"
//             name="cargo"
//             required
//             value={cargo}
//             onChange={(e) => setCargo(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="door">Nombre de portes</label>
//           <input
//             type="text"
//             id="door"
//             name="door"
//             value={door}
//             onChange={(e) => setDoor(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="categorie">Catégorie</label>
//           <input
//             type="text"
//             id="categorie"
//             name="categorie"
//             value={categorie}
//             onChange={(e) => setCategorie(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeSize">Taille du vélo</label>{" "}
//           <input
//             type="text"
//             id="bikeSize"
//             name="bikeSize"
//             value={bikeSize}
//             onChange={(e) => setBikeSize(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="bikeWeight">Poids vélo</label>
//           <input
//             type="text"
//             id="bikeWeight"
//             name="bikeWeight"
//             value={bikeWeight}
//             onChange={(e) => setBikeWeight(e.target.value)}
//           />
//         </div>
//         <div>
//           <label htmlFor="priceByDay">Prix par jour</label>
//           <input
//             type="text"
//             id="priceByDay"
//             name="priceByDay"
//             required
//             value={priceByDay}
//             onChange={(e) => setPriceByDay(e.target.value)}
//           />
//         </div>
//         <span className="titleMedia">Média</span>
//         {Array.from({ length: formCount }).map(
//           (_, index) =>
//             showForm && (
//               <div key={index}>
//                 <>
//                   <div>
//                     <label htmlFor={`fileImage${index}`}>Nom image</label>
//                     <input
//                       type="file"
//                       id={`fileImage${index}`}
//                       name={`fileImage${index}`}
//                       value={mediaFiles}
//                       multiple
//                       onChange={(e) =>
//                         handleMediaFileChange(index, e.target.files)
//                       }
//                     />
//                   </div>
//                   {imagePreviews[formCount - 1] && (
//                     <img
//                       src={imagePreviews[formCount - 1]}
//                       alt={`Image Preview ${formCount - 1}`}
//                       style={{ width: "200px" }}
//                     />
//                   )}
//                   <div>
//                     <label htmlFor={`description${index}`}>Description</label>
//                     <input
//                       type="text"
//                       id={`description${index}`}
//                       name={`description${index}`}
//                       value={mediaDescriptions[index] || ""}
//                       onChange={(e) =>
//                         handleMediaDescriptionChange(index, e.target.value)
//                       }
//                     />
//                   </div>
//                 </>
//                 <button className="btn" onClick={handleAddForm}>
//                   Annuler media
//                 </button>
//               </div>
//             )
//         )}

//         <button className="btn" onClick={handleAddFormFirst}>
//           Ajouter media
//         </button>

//         <input type="submit" value="Valider" />
//       </form>
//     </section>
//   );
// };

// export default VehicleCreate;

// import React, { useState } from "react";
// import "./vehicleCreate.module.scss";
// import { useDispatch } from "react-redux";
// import { createVehicle } from "../../../redux/vehicleRedux/requestVehicle";
// import { createMedia } from "../../../redux/mediaRedux/requestMedia";

// const VehicleCreate = () => {
//   const dispatch = useDispatch();
//   const [formCount, setFormCount] = useState(1);
//   const [showForm, setShowForm] = useState(true);

//   const [typeOfVehicle, setTypeOfVehicle] = useState("");
//   const [brand, setBrand] = useState("");
//   const [vehicleNumber, setVehicleNumber] = useState("");
//   const [range, setRange] = useState("");
//   const [power, setPower] = useState("");
//   const [charging, setCharging] = useState("");
//   const [maximunSpeed, setMaximunSpeed] = useState("");
//   const [typeOfPlug, setTypeOfPlug] = useState("");
//   const [passenger, setPassenger] = useState("");
//   const [cargo, setCargo] = useState("");
//   const [door, setDoor] = useState("");
//   const [categorie, setCategorie] = useState("");
//   const [bikeSize, setBikeSize] = useState("");
//   const [bikeWeight, setBikeWeight] = useState("");
//   const [priceByDay, setPriceByDay] = useState("");
//   const [mediaFiles, setMediaFiles] = useState([]);
//   const [mediaDescriptions, setMediaDescriptions] = useState([]);
//   const [imagePreviews, setImagePreviews] = useState([]);

//   const newVehicle = {
//     typeOfVehicle,
//     brand,
//     vehicleNumber,
//     range,
//     power,
//     charging,
//     maximunSpeed,
//     typeOfPlug,
//     passenger,
//     cargo,
//     door,
//     categorie,
//     bikeSize,
//     bikeWeight,
//     priceByDay,
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const vehicleId = await dispatch(createVehicle(newVehicle));
//     const id = vehicleId.payload.vehicleId;
//     if (vehicleId) {
//       const mediaData = mediaFiles.map((file, index) => ({
//         image: file,
//         description: mediaDescriptions[index],
//         vehicleId: id,
//       }));
//       await dispatch(createMedia(mediaData));
//     }
//   };

//   const handleAddFormFirst = (e) => {
//     e.preventDefault();
//     setFormCount(formCount + 1);
//     setShowForm(true);
//   };

//   const handleAddForm = (e) => {
//     e.preventDefault();
//     setFormCount(formCount - 1);
//   };

//   const handleRemoveForm = (index) => {
//     const updatedMediaFiles = [...mediaFiles];
//     updatedMediaFiles.splice(index, 1);
//     setMediaFiles(updatedMediaFiles);

//     const updatedMediaDescriptions = [...mediaDescriptions];
//     updatedMediaDescriptions.splice(index, 1);
//     setMediaDescriptions(updatedMediaDescriptions);
//   };

//   const handleMediaFileChange = (index, files) => {
//     const updatedFiles = [...mediaFiles];
//     updatedFiles[index] = Array.from(files);
//     setMediaFiles(updatedFiles);
//   };

//   const handleMediaDescriptionChange = (index, value) => {
//     const updatedDescriptions = [...mediaDescriptions];
//     updatedDescriptions[index] = value;
//     setMediaDescriptions(updatedDescriptions);
//   };

//   const updatedImagePreviews = [...imagePreviews];
//   if (files[0].type.startsWith("image/")) {
//     updatedImagePreviews[index] = URL.createObjectURL(files[0]);
//   } else {
//     updatedImagePreviews[index] = null; // Assurez-vous que c'est nul pour les fichiers non pris en charge
//   }
//   setImagePreviews(updatedImagePreviews);
// };

// return (
//   <section className="containerVehicleCreate">
//     <h2>Enregistrer un véhicule</h2>
//     <form onSubmit={handleSubmit} className="formVehicleCreate">
//       <div>
//         <label htmlFor="typeOfVehicle">Type de véhicule</label>
//         <select
//           name="typeOfVehicle"
//           id="typeOfVehicle"
//           value={typeOfVehicle}
//           required
//           onChange={(e) => setTypeOfVehicle(e.target.value)}
//         >
//           <option value="#">choisir le type</option>
//           <option value="car">voiture</option>
//           <option value="scooter">scooter</option>
//           <option value="elecScooter">trottinette</option>
//           <option value="bike">vélo</option>
//         </select>
//       </div>
//       <div>
//         <label htmlFor="brand">Marque</label>
//         <input
//           type="text"
//           id="brand"
//           name="brand"
//           required
//           value={brand}
//           onChange={(e) => setBrand(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="vehicleNumber">Numéro de véhicule</label>
//         <input
//           type="text"
//           id="vehicleNumber"
//           name="vehicleNumber"
//           required
//           value={vehicleNumber}
//           onChange={(e) => setVehicleNumber(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="range">Autonomie</label>
//         <input
//           type="text"
//           id="range"
//           name="range"
//           required
//           value={range}
//           onChange={(e) => setRange(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="power">Puissance</label>
//         <input
//           type="text"
//           id="power"
//           name="power"
//           value={power}
//           onChange={(e) => setPower(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="charging">Recharge</label>
//         <input
//           type="text"
//           id="charging"
//           name="charging"
//           required
//           value={charging}
//           onChange={(e) => setCharging(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="maximunSpeed">Vitesse maximale</label>
//         <input
//           type="text"
//           id="maximunSpeed"
//           name="maximunSpeed"
//           value={maximunSpeed}
//           onChange={(e) => setMaximunSpeed(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="typeOfPlug">Type de prise</label>
//         <input
//           type="text"
//           id="typeOfPlug"
//           name="typeOfPlug"
//           required
//           value={typeOfPlug}
//           onChange={(e) => setTypeOfPlug(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="passenger">Nombre de passagers</label>
//         <input
//           type="text"
//           id="passenger"
//           name="passenger"
//           value={passenger}
//           onChange={(e) => setPassenger(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="cargo">Capacité de chargement</label>
//         <input
//           type="text"
//           id="cargo"
//           name="cargo"
//           required
//           value={cargo}
//           onChange={(e) => setCargo(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="door">Nombre de portes</label>
//         <input
//           type="text"
//           id="door"
//           name="door"
//           value={door}
//           onChange={(e) => setDoor(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="categorie">Catégorie</label>
//         <input
//           type="text"
//           id="categorie"
//           name="categorie"
//           value={categorie}
//           onChange={(e) => setCategorie(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="bikeSize">Taille du vélo</label>{" "}
//         <input
//           type="text"
//           id="bikeSize"
//           name="bikeSize"
//           value={bikeSize}
//           onChange={(e) => setBikeSize(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="bikeWeight">Poids vélo</label>
//         <input
//           type="text"
//           id="bikeWeight"
//           name="bikeWeight"
//           value={bikeWeight}
//           onChange={(e) => setBikeWeight(e.target.value)}
//         />
//       </div>
//       <div>
//         <label htmlFor="priceByDay">Prix par jour</label>
//         <input
//           type="text"
//           id="priceByDay"
//           name="priceByDay"
//           required
//           value={priceByDay}
//           onChange={(e) => setPriceByDay(e.target.value)}
//         />
//       </div>
//       <span className="titleMedia">Média</span>
//       {showForm ? (
//         <div key={formCount - 1}>
//           <>
//             <div>
//               <label htmlFor={`fileImage${formCount - 1}`}>Nom image</label>
//               <input
//                 type="file"
//                 id={`fileImage${formCount - 1}`}
//                 name={`fileImage${formCount - 1}`}
//                 value={
//                   mediaFiles[formCount - 1]
//                     ? mediaFiles[formCount - 1].name
//                     : ""
//                 }
//                 multiple
//                 onChange={(e) =>
//                   handleMediaFileChange(formCount - 1, e.target.files)
//                 }
//               />
//             </div>
//             {imagePreviews[formCount - 1] && (
//               <img
//                 src={imagePreviews[formCount - 1]}
//                 alt={`Image Preview ${formCount - 1}`}
//                 style={{ width: "200px" }}
//               />
//             )}

//             <div>
//               <label htmlFor={`description${formCount - 1}`}>Description</label>
//               <input
//                 type="text"
//                 id={`description${formCount - 1}`}
//                 name={`description${formCount - 1}`}
//                 value={mediaDescriptions[formCount - 1] || ""}
//                 onChange={(e) =>
//                   handleMediaDescriptionChange(formCount - 1, e.target.value)
//                 }
//               />
//             </div>

//             <button
//               className="btn"
//               onClick={() => handleRemoveForm(formCount - 1)}
//             >
//               Annuler media
//             </button>
//           </>
//         </div>
//       ) : null}
//       <button className="btn" onClick={handleAddFormFirst}>
//         Ajouter media
//       </button>

//       <input type="submit" value="Valider" />
//     </form>
//   </section>
// );

// export default VehicleCreate;
