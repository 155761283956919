import { createSlice } from "@reduxjs/toolkit";
import {
  createVehicle,
  deleteVehicle,
  getAllVehicle,
  getAllVehicleModels,
  readVehicle,
  updateVehicle,
} from "./requestVehicle";
import { updateMedia } from "../mediaRedux/requestMedia";

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState: {
    vehicles: [],
    vehicleId: null,
    loading: false,
  },
  reducers: {
    [readVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // READ ALL
      .addCase(getAllVehicle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = action.payload;
      })
      .addCase(getAllVehicle.rejected, (state, action) => {
        state.loading = false;
      })

      // READ ALL MODELS
      .addCase(getAllVehicleModels.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllVehicleModels.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = action.payload;
      })
      .addCase(getAllVehicleModels.rejected, (state, action) => {
        state.loading = false;
      })

      // CREATE
      .addCase(createVehicle.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(createVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles.push(action.payload);
        state.vehicleId = action.payload;
      })
      .addCase(createVehicle.rejected, (state, action) => {
        state.loading = false;
      })

      // UPDATE
      .addCase(updateVehicle.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = state.vehicles.map((vehicle) => {
          if (vehicle.id === action.payload.data.id) {
            return { ...vehicle, ...action.payload.data, Media: vehicle.Media };
          }
          return vehicle;
        });
      })

      .addCase(updateVehicle.rejected, (state, action) => {
        state.loading = false;
      })

      // UPDATE VEHICLE MEDIA

      .addCase(updateMedia.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = state.vehicles.map((vehicle) => {
          if (vehicle.id === action.payload.data.vehicleId) {
            vehicle.Media = vehicle.Media.map((media) => {
              if (media.id === action.payload.data.id) {
                return action.payload.data;
              }
              return media;
            });
            return { ...vehicle, Media: vehicle.Media };
          }
          return vehicle;
        });
      })
      .addCase(updateMedia.rejected, (state, action) => {
        state.loading = false;
      })

      // DElETE
      .addCase(deleteVehicle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = state.vehicles.filter(
          (vehicle) => vehicle.id !== action.payload.data.id
        );
      })
      .addCase(deleteVehicle.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default vehicleSlice.reducer;
