import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicleModels } from "../../../redux/vehicleRedux/requestVehicle";

const CreateBooking = () => {
  const { vehicles } = useSelector((state) => state.vehicles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVehicleModels());
  }, [dispatch]);

  const [slideIndex, setSlideIndex] = useState(0);
  const showImage = "image/showVehicle/";

  const prevSlide = () => {
    let index = slideIndex - 1;
    if (index < 0) {
      if (Array.isArray(vehicles)) {
        vehicles.forEach((vehicle) => {
          vehicle.Media.forEach((element) => {
            index = element.length - 1;
          });
        });
      }
    }
    setSlideIndex(index);
  };

  const nextSlide = () => {
    let index = slideIndex + 1;
    if (index > 0) {
      if (Array.isArray(vehicles)) {
        vehicles.forEach((vehicle) => {
          vehicle.Media.forEach((element) => {
            index = element.length + 1;
          });
        });
      }
    }
    setSlideIndex(index);
  };

  return (
    <main>
      <h1>Création de réservation</h1>
      <section>
        <div className="slider-container">
          <div className="slider">
            {Array.isArray(vehicles) &&
              vehicles.map((vehicle) => (
                <article key={vehicle.id}>
                  {vehicle.Media.map((element, index) => (
                    <div key={element.id} className="media">
                      <img
                        className="imgModel"
                        src={showImage + element.image}
                        alt={element.description}
                        style={{
                          display: slideIndex === index ? "block" : "none",
                        }}
                      />
                    </div>
                  ))}
                </article>
              ))}
          </div>
          <div className="slider-buttons">
            <button className="slider-button prev" onClick={prevSlide}>
              &#10094;
            </button>
            <button className="slider-button next" onClick={nextSlide}>
              &#10095;
            </button>
          </div>
        </div>
      </section>
      <section></section>
      <section></section>
    </main>
  );
};

export default CreateBooking;
