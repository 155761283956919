import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  //postFileRequest,
  postRequest,
  putRequest,
} from "../../components/api/Api";
// import FormData from "form-data";

export const createMedia = createAsyncThunk(
  "/media/create",
  async (form, thunkApi) => {
    const { fulfillWithValue, rejectWithValue } = thunkApi;
    const token = window.localStorage.getItem("token");
    const { status, result, error } = await postRequest(
      "/media/create",
      form,
      token
    );
    console.log(result);
    return error
      ? rejectWithValue(`Cannot sign up - Error status ${status} - ${error}`)
      : fulfillWithValue(result);
  }
);

export const updateMedia = createAsyncThunk(
  "/media/upadate",
  async (form, thunkApi) => {
    console.log("form", form);
    const { fulfillWithValue, rejectWithValue } = thunkApi;

    const token = window.localStorage.getItem("token");
    const { status, result, error } = await putRequest(
      "/media/update",
      form,
      token
    );

    console.log(result);
    return error
      ? rejectWithValue(`Cannot get user - Error status ${status} - ${error}`)
      : fulfillWithValue(result);
  }
);
// export const createMedia = createAsyncThunk(
//   "media/create",
//   async (form, thunkApi) => {
//     const { fulfillWithValue, rejectWithValue } = thunkApi;

//     console.log("form", form[0].image);
//     const formData = new FormData();

//     formData.append("description", form[0].description);
//     formData.append("form", form);
//     console.log("formData", formData);
//     const token = window.localStorage.getItem("token");
//     const { status, result, error } = await postFileRequest(
//       "/media/create",
//       formData,
//       token
//     );

//     console.log(result); // image url

//     return error
//       ? rejectWithValue(
//           `Cannot upload image - Error status ${status} - ${error}`
//         )
//       : fulfillWithValue(result);
//   }
// );
